import React from "react"
import GridSection from "../GridSection"
import styles from "./styles.module.scss"
import data from "../NewsArticles/articles.json"
import blue from "../NewsArticles/blue.webp"

data.newsArticles.sort((x, y) => Date.parse(y.date) - Date.parse(x.date))

function NewsCarousel() {
  return (
    <>
      <section className={styles.news}>
        <GridSection>
          <h3 id={styles.miniHeader}>In The News</h3>
          <div id={styles.gridContainer}>
            {data.newsArticles.map((article, i) => {
              var href = article.href ?? "/news/" + i
              return (
                <a href={href} id={styles.individualBox}>
                  <h1 id={styles.title}>
                    <div id={styles.bannerImg}>{article.category}</div>
                  </h1>
                  <div id={styles.mainBox}>
                    <h3 id={styles.subTitle}> {article.title}</h3>
                    <p id={styles.textinBox}>
                      {article.body.map(x => x.paragraph).join("\n")}
                    </p>
                    <p id={styles.date}>{article.date}</p>
                  </div>
                </a>
              )
            })}
          </div>
        </GridSection>
      </section>
    </>
  )
}

export default NewsCarousel
